import React from 'react';
import { registerClass, IFNTheme } from '@food/css-manager';

const style = registerClass(
	(t: IFNTheme) => `
position: relative;
min-width: 120px;
min-height: 120px;
margin-top: 10vh;
margin-bottom: 10vh;

> div {
	position: absolute;
	height: 60px;
	width: 60px;
	border: 3px solid transparent;
	border-top-color: ${t.colors.primary.plain};
	top: 50%;
	left: 50%;
	margin: -30px;
	border-radius: 50%;
	animation: spin 2s linear infinite;
 
	&:before, &:after{
		content:'';
		position: absolute;
		border: 3px solid transparent;
		border-radius: 50%;
	}
 
	&:before{
		border-top-color: ${t.colors.secondary.plain};
		top: -12px;
		left: -12px;
		right: -12px;
		bottom: -12px;
		animation: spin 3s linear infinite;
	}
 
	&:after{
		border-top-color: ${t.colors.alt.plain};
		top: 6px;
		left: 6px;
		right: 6px;
		bottom: 6px;
		animation: spin 4s linear infinite;
	}
}

@keyframes spin{
	0% {transform: rotate(0deg);}
	100% {transform: rotate(360deg);}
}
`,
);

export const Spinner: React.FC<{}> = () => (
	<div className={style}>
		<div />
	</div>
);
