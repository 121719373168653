import 'core-js/modules/es.regexp.constructor';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Fluent from 'i18next-fluent';
import Backend from 'i18next-fluent-backend';
import { config } from './config';

i18n
	.use(Fluent)
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: [config.LANG],
		ns: ['translations'],
		defaultNS: 'translations',
		debug: process.env.NODE_ENV !== 'production',
		interpolation: {
			escapeValue: false,
		},
		react: {
			wait: true,
		},
		backend: {
			loadPath: config.FLUENT_LOAD_PATH,
		},
		whitelist: [config.LANG || 'it'],
	});

export default i18n;
