import { IFNTheme, registerGlobal } from '@food/css-manager';

//const fontFamily = '"Montserrat", sans-serif;';
registerGlobal(
	(t: IFNTheme) => `
	line-height: 1;
	margin: 0;
	padding: 0;
	font-family: ${t.font.family};
	font-size: ${t.font.baseSize}px;
	font-weight: ${t.font.weights.regular};
	color: ${t.colors.ink};
	
	input {
		font-size: ${t.font.baseSize}px;
	}
	
	button {
		font-family: ${t.font.family};
		font-size: ${t.font.baseSize}px;
		line-height: 1em;
		box-shadow: none;
		border-radius: 0;
	}
	
	&, * {
		box-sizing: border-box;
	}
	
	a, a:hover {
		text-decoration: none;
	}
	a, a:hover, a:active, a:visited {
		color: inherit;
	}

	div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed,
	figure, figcaption, footer, header, hgroup,
	main, menu, nav, output, ruby, section, summary,
	time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}
	
	/* HTML5 display-role reset for older browsers */
	article, aside, details, figcaption, figure,
	footer, header, hgroup, main, menu, nav, section {
		display: block;
	}
	
	ol, ul {
		list-style: none;
	}
	
	blockquote, q {
		quotes: none;
	}
	
	blockquote:before, blockquote:after,
	q:before, q:after {
		content: '';
		content: none;
	}
	
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	
	@media only screen and (max-width: ${t.breakpoints.mobileMax}px) {
		// font-size: ${(t.font.baseSize * 7) / 8}px;
	}
	
	h1:before, h2:before {
		display: none !important;
	}
	
	
	/* RESET DA TEMA WORDPRESS */
	button, input[type="button"], input[type="reset"], input[type="submit"] {
		text-shadow: none;
	}
	
	`,
);
