import React, { Suspense } from 'react';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import { injectCSS, refreshCSS } from '@food/css-manager';
import { init } from '@food/tracking';
import { config } from './utils/config';
import './utils/i18n';
import './utils/theme';
import * as git from './static/rev.json';
import { ArgumentsType } from './types';
import { Spinner } from './components/Spinner';
import { RenderNodeContext } from './components/RenderNodeContext';
// import ReactGA from 'react-ga';
// import { getUserData, isLogged } from '@food/auth/build/dist';

const isValidApplication = (application: string | null): application is null | 'ecommerce' | 'agenda' | 'awards' | 'newsstand' | 'profile' | 'auth' | 'knowledgecenter' | 'landingpagemagazine' | 'resetpassword' => {
	return !application || ['ecommerce', 'agenda', 'awards', 'newsstand', 'profile', 'auth', 'knowledgecenter', 'landingpagemagazine', 'resetpassword'].includes(application)
}

init({
	baseUrl: config.API_ENDPOINT.TRACKING,
	bufferLength: 1,
	targetSite: config.TARGET_SITE,
});

Sentry.init({
	dsn: config.SENTRY_SERVER,
	release: (git as any).rev,
	environment: process.env.NODE_ENV,
});

const ProfileLazy = React.lazy(() => {
	return import('./components/apps/Profile').then((args) => {
		refreshCSS();
		return args;
	});
});

const AuthLazy = React.lazy(() => {
	return import('./components/apps/Auth').then((args) => {
		refreshCSS();
		return args;
	});
});

const AwardLazy = React.lazy(() => {
	return import('./components/apps/Award').then((args) => {
		refreshCSS();
		return args;
	});
});

const AgendaLazy = React.lazy(() => {
	return import('./components/apps/Agenda').then((args) => {
		refreshCSS();
		return args;
	});
});

const NewsStandLazy = React.lazy(() => {
	return import('./components/apps/NewsStand').then((args) => {
		refreshCSS();
		return args;
	});
});

const KnowledgeCenterLazy = React.lazy(() => {
	return import('./components/apps/KnowledgeCenter').then((args) => {
		refreshCSS();
		return args;
	});
});

const LandingPageMagazineLazy = React.lazy(() => {
	return import('./components/apps/LandingPageMagazine').then((args) => {
		refreshCSS();
		return args;
	});
});

const ReservedContentLazy = React.lazy(() => {
	return import('./components/apps/ReservedContent').then((args) => {
		refreshCSS();
		return args;
	});
});

const EcommerceLazy = React.lazy(() => {
	return import('./components/apps/Ecommerce').then((args) => {
		refreshCSS();
		return args;
	});
});

const ResetPasswordLazy = React.lazy(() => {
	return import('./components/apps/ResetPassword').then((args) => {
		refreshCSS();
		return args;
	});
});

const render = (args: ArgumentsType): void => {
	if (config.beforeRender) {
		config.beforeRender();
	}

	const { portalClasses, prefix, renderNode, application } = args;

	injectCSS({
		theme: config.theme,
		portalClasses: portalClasses || [],
		prefix,
		styleTagId: application,
	});

	ReactDOM.render(
		<RenderNodeContext.Provider value={renderNode}>
			<Suspense fallback={<Spinner />}>
				{args.application === 'profile' && <ProfileLazy args={args} />}
				{args.application === 'awards' && <AwardLazy />}
				{args.application === 'newsstand' && <NewsStandLazy args={args} />}
				{args.application === 'knowledgecenter' && <KnowledgeCenterLazy args={args} />}
				{args.application === 'resetpassword' && <ResetPasswordLazy args={args} />}
				{args.application === 'landingpagemagazine' && <LandingPageMagazineLazy args={args} />}
				{args.application === 'agenda' && <AgendaLazy args={args} />}
				{args.application === 'reserved_content' && (
					<ReservedContentLazy args={args} />
				)}
				{args.application === 'auth' && <AuthLazy args={args} />}
				{args.application === 'ecommerce' && <EcommerceLazy />}
			</Suspense>
		</RenderNodeContext.Provider>,
		renderNode,
	);
};

(window as any).render = render;

if (process.env.NODE_ENV !== 'production') {
	const node = document.getElementById('root');
	if (node !== null) {
		let renderConfig: ArgumentsType;
		const search = window.location.search;
		const application = new URLSearchParams(search).get('application');
		switch (application) {
			case 'reserved_content':
				renderConfig = {
					renderNode: node,
					application,
					hiddenNodes: ['swag'],
					authRules: [
						{ type: 'TYPEFORM', achievement: 'typeform:F3wQwR' },
						{ type: 'USER' },
					]
				};
				break;
			case 'auth':
				renderConfig = {
					renderNode: node,
					application,
					modalArgs: {}
				};
				break;
			case 'landingpagemagazine':
				renderConfig = {
					renderNode: node,
					magazineId: 'F0i_9aCad9k~a5b',
					textColor: '#FF0000',
					showIndex: true,
					application
				};
				break;
			default:
				if (!isValidApplication(application)) {
					throw new Error('Unsupported Application: ' + application);
				}
				renderConfig = {
					renderNode: node,
					application: application ? application : 'knowledgecenter'
				}

		}
		render(renderConfig);
	}
}

